// Dark Theme
// By:
.dark[app-theme] {
    --background-secondary: #000;
    --tile-bg: #040404;;
    --tile-secondary-bg: #111;
    --nft-section-bg: #111;
    --text-secondary: #bbbbbb;
    --text-muted: #999;
    --text-inverted: #202020;
    --text-inverted-muted: #363636;
    --highlight: #00d9ff;
    --highlight-secondary: #E5F2FF;
    --feed-container-hover: #0a0a0a;
    --feed-list-item-hover: #0a0a0a;
    --error: #FF6939;
    --error-light: #FFECE7;
    --success: #2CC99A;
    

    --background: #000;
    --text: #EFF3F8;
    --grey: #999;
    --secalt: #111;
    --textalt: #eee;
    --norm: #eee;
    --formbg: #111;
    --link: #00d9ff;
    --hover: #72b6ff;
    --border: #222;
    --borderheavy: #4f4f4f;
    --mborder: #484848;
    --filter: invert(98%) sepia(1%) saturate(264%) hue-rotate(181deg) brightness(116%) contrast(100%);
    --unread: #1a293f;
    --topbar: #2E2E2E;
    --cblue: #0058F7;
    --cred: #fe3537;
    --cgreen: #19B028;
    --button: #0058F7;
    --loading: #999;
    --glow: #f50057;
}
