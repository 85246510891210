.btn-outline-dark {
  color: var(--text);
}

.btn-outline-darker {
  color: var(--text);
  border: 1px solid var(--text);
}

.btn-outline-darker:hover {
  color: #fff;
  background-color: #343a40;
}

.btn-outline-darker.focus,
.btn-outline-darker:focus {
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-outline-darker.disabled,
.btn-outline-darker:disabled {
  color: #343a40;
  background-color: transparent
}

.btn-outline-darker:not(:disabled):not(.disabled).active,
.btn-outline-darker:not(:disabled):not(.disabled):active,
.show>.btn-outline-darker.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
}

.btn-outline-darker:not(:disabled):not(.disabled).active:focus,
.btn-outline-darker:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-darker.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.list-group-item {
  background-color: var(--bg);
  border: 1px solid var(--border);
  color: var(--text);
}

.left-bar__post-button.custom-button-height {
  height: 31px !important;
  padding-top: 5px;
  font-size: 17px;
  line-height: normal;
}