// Icy Dark Theme
// By: @ItsAditya
.icydark[app-theme] {
    --background-secondary: #15202B;
    --tile-bg: #15202B;
    --tile-secondary-bg: #243241;
    --nft-section-bg: #243241;
    --text-secondary: #bbbbbb;
    --text-muted: #999;
    --text-inverted: #202020;
    --text-inverted-muted: #363636;
    --feed-container-hover: #202f3c;
    --feed-list-item-hover: #202f3c;
    --highlight: #669DFF;
    --highlight-secondary: #E5F2FF;
    --error: #FF6939;
    --error-light: #FFECE7;
    --success: #2CC99A;

    --background: #192635;
    --text: #EFF3F8;
    --grey: #999;
    --secalt: #111;
    --textalt: #eee;
    --norm: #eee;
    --formbg: #111;
    --link: #669DFF;
    --hover: #72b6ff;
    --border: #253544;
    --borderheavy: #253544;
    --mborder: #253544;
    --filter: invert(98%) sepia(1%) saturate(264%) hue-rotate(181deg) brightness(116%) contrast(100%);
    --unread: #1a293f;
    --topbar: #2E2E2E;
    --cblue: #0058F7;
    --cred: #fe3537;
    --cgreen: #19B028;
    --button: #0058F7;
    --loading: #999;
    --glow: #f50057;
}
